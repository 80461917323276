<template>
  <vx-card style="padding-left: 10px">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="events"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th>Logo</vs-th>
          <vs-th sort-key="name">Name</vs-th>
          <vs-th sort-key="description">Description</vs-th>
          <vs-th sort-key="start">Date</vs-th>
          <vs-th sort-key="start">Start - End</vs-th>
          <vs-th sort-key="venueName">Venue</vs-th>
          <vs-th sort-key="location">Location</vs-th>
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].logo">
              <div class="items-center lg:flex">
                <img
                  :src="getImage(data[indextr].logo)"
                  style="height: 130px;"
                />
              </div>
            </vs-td>
            <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
            <vs-td :data="data[indextr].description">{{ data[indextr].description }}</vs-td>
            <vs-td :data="data[indextr].start">{{ momentDate(data[indextr].start) }}</vs-td>
            <vs-td :data="data[indextr].start">{{ momentTime(data[indextr].start, data[indextr].end) }}</vs-td>
            <vs-td :data="data[indextr].venueName">{{ data[indextr].isOnlineEvent ? 'Online Event' : data[indextr].venueName  }}</vs-td>
            <vs-td :data="data[indextr].location">{{ data[indextr].isOnlineEvent ? '' : data[indextr].location  }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="Disabled/Enabled">
                <vs-switch
                  v-model="data[indextr].status"
                  @click="updateStatusHandler(data[indextr]._id)"/>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "eventbrite-list",
  data() {
    return {
      noDataText: "",
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "start",
        dir: "asc",
        page: 1,
        limit: 10
      },
      serverResponded: false,
      events: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("admin", [
      "fetchEventBriteList",
      "updateEventBrite"
    ]),
    momentDate(date) {
      return moment(date).format("ddd, MMM YYYY");
    },
    momentTime(start, end) {
      return (
        moment(start).format("HH:mm a") + " to " + moment(end).format("HH:mm a")
      );
    },
    getImage(logo) {
      return logo.replace(
        process.env.VUE_APP_BASE_API_URL + "/api/generate-url/",
        "https://img.evbuc.com/"
      );
    },
    async getEventList() {
      await this.fetchEventBriteList(this.dataTableParams)
        .then(res => {
          this.events = (res.data.data.docs || []).map((evt) => ({...evt, status: typeof evt.status === 'undefined' ? true : evt.status }));
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;
          this.noDataText = "No Event Brite";
        })
        .catch(err => {
          console.log("This is the error ::", err);
        });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.noDataText = "Loading...";
        this.events = [];
        this.getEventList();
      }, 500);
    },
    handleChangePage(page) {
      this.noDataText = "Loading...";
      this.events = [];
      this.dataTableParams.page = page;
      this.getEventList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.noDataText = "Loading...";
      this.events = [];
      this.getEventList();
    },
    async updateStatusHandler(id) {
      await this.updateEventBrite(id)
        .then(res => {
          this.getEventList();
          this.popUpDelete = false;
          this.$vs.notify({
            title: "Success",
            text: "EventBrite status updated successfully.",
            color: "success"
          });
        })
        .catch(err => {
          this.popUpDelete = false;
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger"
          });
        });
    },
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.noDataText = "Loading...";
        this.events = [];
        this.getEventList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.noDataText = "Loading...";
        this.events = [];
        this.getEventList();
      }
    },
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  async created() {
    this.noDataText = "Loading...";
    this.events = [];
    await this.getEventList();
  }
};
</script>
